@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body, html, #root {
    height: 100%;
    width: 100%;
  }

  .btn-primary {
    @apply text-sm font-semibold outline-none text-white text-center p-3 rounded-2.5 bg-primary-purple hover:rounded-2xl hover:drop-shadow-md transition-all;
  }
  
  .btn-secondary {
    @apply text-sm font-medium outline-none text-primary-purple text-center px-3 py-3 rounded-2.5 bg-primary-section hover:rounded-2xl hover:drop-shadow-sm transition-all;
  }

  button:disabled {
    @apply grayscale cursor-default;
  }

  .flex-cool {
    @apply flex flex-col;
  }

  input[type="text"], input[type="password"], textarea {
    @apply border border-slate-200 rounded-lg focus:border-indigo-500 text-sm text-slate-900 p-3 outline-none;
  }

  .select {
    @apply border border-slate-200 rounded-lg text-left focus:border-indigo-500 text-sm text-slate-900 p-3 outline-none appearance-none;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 0;
  }

  .talent-finder-table {
    border-collapse: collapse;
  }

  td.fitwidth {
    width: 1px;
    white-space: nowrap;
  }

  .talent-finder-table td {
    @apply text-xs p-3 text-text-secondary text-left;
  }

  .talent-finder-table td:first-child {
    @apply rounded-l-2.5;
  }
  
  .talent-finder-table td:last-child {
    @apply rounded-r-2.5;
  }
  
  .talent-finder-table tr:nth-child(odd) {
    @apply bg-[#f4f4f4];
  }

  .talent-finder-table thead th {
    @apply font-light text-text-secondary px-3 py-2 text-left bg-white;
  }
  
  .pnotify-error {
    pointer-events: none;
    z-index: 10000000000 !important;
    background-color: white;
    @apply rounded-lg shadow-lg;
  }
}